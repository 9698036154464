/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import { renderSnippet } from "../utils/functions";
import useAPI from "../hooks/useApi";

import Layout from "../components/Layout";
import PageContainer from "../components/PageContainer";

const Confirmation = () => {
  const { data, getOrder } = useAPI();

  if (typeof window !== "undefined") {
    localStorage.removeItem("cart");
  }

  // SEND GET request tot the server to receive confirmation from klarna on page render
  useEffect(() => {
    getOrder();
  }, [getOrder]);

  // Render HTML confirmation snippet from Klarna
  useEffect(() => {
    renderSnippet(data);
  }, [data]);

  return (
    <Layout>
      <PageContainer>
        <div sx={{ height: "100%" }} id="my-checkout-container" />
      </PageContainer>
    </Layout>
  );
};

export default Confirmation;
